.paginationNav a {
    color: #4c4a4a !important;
    padding: 5px 2px !important;
    border: 0;
}

.paginationNav a:hover {
    color: #03045e !important;
    background: none !important;
    font-weight: bolder !important;
}

.paginationNav a:focus {
    background: none !important;
}



.paginationItem a {
    color: #4c4a4a !important;
    padding: 5px !important;
    border: 0;
}

.paginationItemSelected {
    color: #1a831a !important;
    /*padding: 0px !important;*/
    border: 0;
}

.paginationItemSelected span {
    background: none !important;
    border: none !important;
    padding: 5px 5px !important;
    color: #03045e !important;
    text-underline-offset: 3px !important;
    text-underline-color: #03045e !important;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
}


.paginationItem a:hover,
.paginationItem a:focus {
    color: #03045e !important;
    background: none !important;
    text-underline-offset: 3px !important;
    text-underline-color: #03045e !important;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
}