@import url('https://fonts.googleapis.com/css?family=Exo+2:500|Sonsie+One&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');


.App {
    /*text-align: center;*/
    padding: 10px;
}

.light-link {
    color: white !important;
}

.container {
    margin-bottom: 50px !important;
}




/* ========= Buttons and Links =============== */

.btn-link:hover {
    background: transparent !important;
}


/* ========= END Buttons and Links =========== */



.dark-blue-link-button {
    /*font-weight: bolder !important;*/
    text-decoration: none !important;
    color: #222e85 !important;
    font-size: 10pt !important;
}

.dark-blue-link-button:hover {
    background-color: #d8f5fd !important;
    text-underline: #d20c64 !important;
    /*text-decoration-line: underline !important;*/
    /*text-decoration-color: #fcb519 !important;*/
    text-decoration-thickness: 2px !important;
    text-underline-offset: 5px;
}

.dark-blue-link-button:focus {
    background-color: #d8f5fd !important;
}

.rbt input {
    /*padding: 1.375rem .75rem;*/
}

.rbt-close-content {
    display: none;
}

.rbt-aux .rbt-close {
    margin-top: 0.75rem !important;
    font-size: 0.75rem !important;
}

a {
    --bs-link-color: #383d41;
    color: #383d41 !important;
    text-decoration: none !important;
}

/*.form-floating label::after {*/
/*    content: "test";*/
/*    color: #d20c64 !important;*/

/*}*/

.form-floating > .form-control-plaintext ~ label, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
    color: #233ef3 !important;
    transform: scale(.95) translateY(-1.99rem) translateX(.25rem) !important;
}


a:visited {
    --bs-link-color: #383d41;
    color: #383d41 !important;
    text-decoration: none;
}

a:hover {
    /*--bs-link-color: #0a1a86;*/
    /*color: #0a1a86 !important;*/
    /*background-color: #f2fcff !important;*/

    /*text-decoration: underline !important;*/
    /*text-underline: #1f7a06 !important;*/
    /*text-decoration-thickness: 2px !important;*/
    /*text-underline-offset: 5px;*/
}


.page-header-text {
    color: #282c34;
    font-size: 30px;
    padding: 5px 0 5px 5px;
}

/*.page-link {*/
/*  color: #383d41 !important;*/
/*}*/

.page-item {
    --bs-pagination-active-bg: #141b53 !important;
    --bs-pagination-active-color: #ffff;
    --bs-pagination-color: #383d41;
    color: white !important;
}

.page-link:active {
    --bs-pagination-active-bg: #282c34;
}

.center {
    margin: auto;
    width: 50%;
    padding: 10px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.bg-dark-blue {
    background-color: #03045e;
}

.bg-light-grey {
    background-color: #edebeb;
}

.icon-account-login {
    fill: #ffffff;
}

.brand-title {
    font-family: 'Abel', sans-serif;
    font-size: 20px;
    color: #ffffff;
    padding: 0;
}

.release-stage {
    font-family: 'Caveat', cursive;
    font-size: 20px;
}

.brand-title-contrast {
    /*font-family: 'Sonsie One', cursive;*/
    font-family: 'Abel', sans-serif;
    font-size: 25px;
    color: #ffe91b;
}

.brand-pre-title-welcome {
    /*font-family: 'Sonsie One', cursive;*/
    font-family: 'Abel', sans-serif;
    font-size: 30px;
    color: #ffffff;
}

.brand-title-welcome {
    /*font-family: 'Sonsie One', cursive;*/
    font-family: 'Abel', sans-serif;
    font-size: 38px;
    color: #023e8a;
}


.brand-subtitle {
    font-family: 'Exo 2', sans-serif;
    font-size: 15px;
}

.btn-default {
    background-color: #073e7e !important;
    color: white !important;
    font-family: 'Exo 2', sans-serif;
}

.btn-default:hover {
    background-color: #141b53 !important;
    color: white !important;
}

.btn-outline-default {
    color: #073e7e !important;
    background-color: #ffffff !important;
    border-color: #073e7e !important;
    /*--bs-btn-color: #0d6efd;*/
    /*--bs-btn-border-color: #0d6efd;*/
    /*--bs-btn-hover-color: #fff;*/
    /*--bs-btn-hover-bg: #0d6efd;*/
    /*--bs-btn-hover-border-color: #0d6efd;*/
    /*--bs-btn-focus-shadow-rgb: 13,110,253;*/
    /*color: #fff;*/
    /*--bs-btn-active-bg: #0d6efd;*/
    /*--bs-btn-active-border-color: #0d6efd;*/
    /*--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);*/
    /*--bs-btn-disabled-color: #0d6efd;*/
    /*--bs-btn-disabled-bg: transparent;*/
    /*--bs-btn-disabled-border-color: #0d6efd;*/
    /*--bs-gradient: none;*/
}

.btn-outline-default:hover {
    color: #ffffff !important;
    background-color: #073e7e !important;
    border-color: #073e7e !important;
}

.btn-yellow {
    background-color: #fcb519 !important;
    --bs-btn-border-color: #bb8b23 !important;
    border-color: yellow;
    /*text-shadow: 2px 2px 4px #000000;*/
    text-shadow: 1px 1px 2px #5e5e5e, 0 0 5px orange, 0 0 5px orangered;
    color: #ffffff !important;
    font-size: large !important;
    font-family: 'Exo 2', sans-serif;
}

.btn-space {
    margin-left: 10px;
}


.navbar-toggler-custom {
    border-color: rgba(250, 229, 140, 0.37) !important;
}

.navbar-toggler-custom:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 1px 0px 0 0 !important;
}


.btn-outline-blue {
    color: #343a40;
    border-color: #073e7e !important;
    font-family: 'Exo 2', sans-serif;
}

.btn-outline-blue:hover {
    color: #ffffff !important;
    background-color: #141b53 !important;
    border-color: #073e7e !important;
}


/*grid ==============================*/
.grid-header {
    color: #3A3A3A;
    font-weight: bolder;
    background-color: #ebf1fc;
    padding: 3px 0 3px 0;
}

.grid-row-alternative {
    background: #F4F5FC;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* labels */
.label-embedded {

    /*style={{*/
    /*marginLeft: "15px",*/
    /*marginTop: "-14px",*/
    /*padding: "0 2px 0 5px",*/
    /*position: "absolute",*/
    /*backgroundColor: "white",*/
    /*color: "#03045e",*/
    /*border: "1px solid white"}}*/
    /*className="small"*/

    margin: -14px 0 0 15px;
    padding: 0 2px 0 5px;
    position: absolute;
    background-color: white;
    color: #03045e;
    border: 1px solid white;
    border-radius: 15px;
    font-size: 0.9em;

}


/*card ===============================*/
.card-number input {
    width: 20em;
    font-size: 20px;
    border: 1px gray dotted;
    padding: 3px;
    margin: 3px;
    font-weight: bolder;
}

.clover-footer {
    /*position: fixed;*/
    background-color: #ebf1fc !important;
    right: 0;
    margin-bottom: 30px;
    bottom: 0;
    left: 0;
    z-index: 1029;
}